import type { PageProps } from './ui-components/types';

import { Card, CardContent, CardFooter, CardHeader } from '@andes/card';

import withHead from '@app/components/with-head';

import {
  AliasCardHeader,
  AliasCardContent,
  AliasCardFooter,
  AliasCardSecurityAlert,
  AliasForm,
} from './ui-components';
import { setSettings, getProps } from './ui-components/services';

export const getServerSideProps = getProps;

export const setPageSettings = setSettings;

export const Alias = ({
  title,
  isDonationUser = false,
  siteId = '',
  image = '',
  url = '',
  brand = '',
  currencyId,
  alias = '',
}: Partial<PageProps>) => (
  <div className="calculator-container">
    <AliasForm
      min={isDonationUser ? 1000 : 0}
      currencyId={currencyId}
      alias={alias}
      siteId={siteId}
    >
      {({ onChange, entryMode, loading, isFormValid }) => (
        <div className="card-calculator">
          <Card shadow="flat" component="section" paddingSize={0}>
            <CardHeader>
              <div>
                <AliasCardHeader image={image} url={url} brand={brand} />
              </div>
            </CardHeader>
            <CardContent>
              <AliasCardContent
                title={title}
                amountFieldProps={{
                  entryMode,
                  onChange,
                  currencyId,
                }}
              />
            </CardContent>
            <CardFooter showBorder={false}>
              <AliasCardFooter
                buttonProps={{
                  loading,
                  disabled: !isFormValid,
                }}
              />
            </CardFooter>
          </Card>

          <AliasCardSecurityAlert />
        </div>
      )}
    </AliasForm>
  </div>
);

export const hydrate = 'hydrateRoot';

export default withHead(Alias);
