import type { PageProps } from '../types';

import { Image } from 'nordic/image';
import { Typography } from '@andes/typography';
import { Thumbnail } from '@andes/thumbnail';

import Icon from '@app/components/Icons';

import { getUrlWithoutProtocol } from '../utils';

type Props = Pick<PageProps, 'brand' | 'image' | 'url'>;

export const AliasCardHeader = ({
  image = '',
  url = '',
  brand = '',
}: Props) => (
  <header className="card-calculator__header">
    <Thumbnail
      hierarchy="loud"
      size="56"
      className={`${image ? '' : 'card-thumbnail__thumbnail'}`}
    >
      {image ? (
        <Image data-testid="image-brand" alt={brand} src={image} />
      ) : (
        <Icon id="officialStores" />
      )}
    </Thumbnail>
    <div className="card-calculator__header-desc">
      <Typography
        component="p"
        size="xs"
        className="card-calculator__header-url"
      >
        {getUrlWithoutProtocol(url)}
      </Typography>
    </div>
  </header>
);

export default AliasCardHeader;
