import React from 'react';

import { useI18n } from 'nordic/i18n';
import { ButtonText, ProgressButton, type ButtonProps } from '@andes/button';

import { gaTracker } from '@app/utils/tracks';

type Props = {
  buttonProps?: Partial<ButtonProps>;
};

export const AliasCardFooter = ({ buttonProps }: Props) => {
  const { i18n } = useI18n();
  const buttonText = i18n.gettext('Elegir medio de pago');

  const onClick = React.useCallback(() => {
    gaTracker('SS_TULINK_PUBLIC_CLICK_SUBMIT_BUTTON');
  }, []);

  return (
    <div className="card-calculator__footer">
      <ProgressButton
        {...buttonProps}
        type="submit"
        form="form-alias-calculator"
        hierarchy="loud"
        progressLabel={buttonText}
        onClick={onClick}
      >
        <ButtonText>{buttonText}</ButtonText>
      </ProgressButton>
    </div>
  );
};

export default AliasCardFooter;
