import type { IntentProps, IntentResult } from '../types';
import type React from 'react';

import { Form } from '@andes/form';

import useIntent from '../hooks/use-intent';

type AliasFormProps = IntentProps & {
  children: (props: IntentResult) => React.ReactNode;
};

export const AliasForm = ({
  min,
  children,
  currencyId,
  alias,
  siteId,
}: AliasFormProps) => {
  const { price, loading, isFormValid, entryMode, onChange, handleSubmit } =
    useIntent({ min, currencyId, alias, siteId });

  return (
    <Form id="form-alias-calculator" onSubmit={handleSubmit}>
      {children({ price, onChange, entryMode, loading, isFormValid })}
    </Form>
  );
};

export default AliasForm;
