import type { IntentProps, IntentResult } from '../../types';

import React from 'react';

import { getFractionAndDecimals } from '@andes/amount-field';

import { api } from '@clients/rest-client';
import { gaTracker } from '@app/utils/tracks';

const useIntent = ({
  min,
  currencyId,
  alias,
  siteId,
}: Omit<IntentProps, 'children'>): IntentResult & {
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
} => {
  const [price, setPrice] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  const isFormValid = React.useMemo(() => {
    const numericString = price.replace(/\D/g, '');
    const number = parseInt(numericString, 10);

    return min ? number >= min : number > 0;
  }, [price, min]);

  const entryMode = React.useMemo(
    () =>
      ['COP', 'CLP'].includes(String(currencyId))
        ? 'fromFraction'
        : 'fromDecimals',
    [currencyId],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      gaTracker('SS_TULINK_PUBLIC_SUBMIT');

      if (price) {
        const { fraction, decimals } = getFractionAndDecimals(price, ',');
        const unitPrice = Number(decimals)
          ? Number(`${String(fraction)}.${String(decimals)}`)
          : Number(fraction);

        setLoading(true);

        api
          .post<{
            url: string;
          }>(`/alias/${String(alias)}/create-intent`, {
            data: {
              alias,
              unit_price: unitPrice,
              site: siteId,
            },
          })
          .then(({ data }) => {
            gaTracker('SS_TULINK_PUBLIC_SUBMIT_SUCCESS');
            window.location.replace(data.url);
          })
          .catch(({ response }: { response: { data: { url: string } } }) => {
            gaTracker('SS_TULINK_PUBLIC_SUBMIT_ERROR');
            window.location.replace(response.data.url);
          })
          .finally(() => setLoading(false));
      }
    },
    [price, alias, siteId],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  return {
    price,
    loading,
    isFormValid,
    entryMode,
    handleSubmit,
    onChange,
  };
};

export default useIntent;
