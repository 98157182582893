import type React from 'react';

import { useI18n } from 'nordic/i18n';
import { Typography } from '@andes/typography';
import { AmountField, type AmountFieldProps } from '@andes/amount-field';

const MAX_AMOUNT_FIELD_DIGITS = 9;
const AMOUNT_FIELD_ID = 'amount-field';

type Props = {
  amountFieldProps?: Partial<AmountFieldProps>;
  title?: string;
};

export const AliasCardContent = ({
  amountFieldProps,
  title = 'Ingresa el monto de tu compra',
}: Props) => {
  const { i18n } = useI18n();

  return (
    <div className="card-calculator__body">
      <Typography
        size="xs"
        component="h2"
        type="title"
        className="card-calculator__title"
      >
        {i18n.gettext(title)}
      </Typography>

      <AmountField
        inputProps={{
          type: 'number',
        }}
        data-testid={AMOUNT_FIELD_ID}
        id={AMOUNT_FIELD_ID}
        maxDigits={MAX_AMOUNT_FIELD_DIGITS}
        srLabel={AMOUNT_FIELD_ID}
        {...amountFieldProps}
      />
    </div>
  );
};

export default AliasCardContent;
