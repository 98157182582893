import { useI18n } from 'nordic/i18n';

import Icon from '@app/components/Icons';

export const AliasCardSecurityAlert = () => {
  const { i18n } = useI18n();

  return (
    <span className="card-calculator__security-message">
      <Icon id="securityPayment" />
      {i18n.gettext('Pago seguro por Mercado Pago')}
    </span>
  );
};

export default AliasCardSecurityAlert;
